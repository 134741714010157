import React, {useEffect, useRef} from "react";
import {HotTable} from '@handsontable/react';

import {
    Backdrop,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    Snackbar,
    TextField
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from '@material-ui/lab';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment'
import {post} from "../../common/ApiUtils";
import {CatalogApi} from "../../api/CatalogApi";

const AD_TYPE_OPTIONS = [
    {value: '_rv', label: 'rewarded'},
    {value: '_int', label: 'interstitial'},
    {value: '_ban', label: 'banner'},
];

const METRICS = {
    e_cpm: 'eCPM',
    dav: 'DAV',
    impressions: 'Impressions',
    impressions_tag: 'Impressions (TAG)',
    revenue: 'Revenue',
    revenue_tag: 'Revenue (TAG)'
};

const GROUPING = {
    ad_revenue_network: 'Network',
    country_code: 'Country',
    ad_unit_name: 'Ad Unit Name',
    total: 'Total',
};

const useStyles = makeStyles((_theme) => ({
    root: {
        'margin-top': '1rem',
    },
    backdrop: {
        zIndex: _theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


export default function AdMonetizationReport(_props) {

    document.title = 'Ad Monetization';

    const classes = useStyles();
    const [mainPeriodStart, setMainPeriodStart] = React.useState(moment().startOf('isoWeek').subtract(1, 'week'));
    const [mainPeriodEnd, setMainPeriodEnd] = React.useState(moment().endOf('isoWeek').subtract(1, 'week'));
    const [periodToCompareStart, setPeriodToCompareStart] = React.useState(undefined);
    const [periodToCompareEnd, setPeriodToCompareEnd] = React.useState(undefined);
    const [appOptions, setAppOptions] = React.useState([]);
    const [appIds, setAppIds] = React.useState([]);
    const [countryOptions, setCountryOptions] = React.useState([]);
    const [country, setCountry] = React.useState([]);
    const [adNetwork, setAdNetwork] = React.useState([]);
    const [adNetworkOptions, setAdNetworkOptions] = React.useState([]);
    const [adUnitName, setAdUnitName] = React.useState([]);
    const [adUnitsOptions, setAdUnitsOptions] = React.useState([]);
    const [adType, setAdType] = React.useState([]);
    const [trafficByTag, setTrafficByTag] = React.useState(false);
    const [trafficWithoutTag, setTrafficWithoutTag] = React.useState(false);
    const [outputMetrics, setOutputMetrics] = React.useState([Object.keys(METRICS)[0], Object.keys(METRICS)[4]]);
    const [outputMetricsOptions, setOutputMetricsOptions] = React.useState(Object.keys(METRICS));
    const [compareMetric, setCompareMetric] = React.useState(Object.keys(METRICS)[2]);
    const [grouping, setGrouping] = React.useState(Object.keys(GROUPING)[0]);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const [gridState, setGridState] = React.useState(undefined);

    const hotTableComponent = useRef()

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        CatalogApi.getMainAppWithPlatformOptions()
            .then(options => {
                debugger
                setAppOptions(options);
                setAppIds([options[0].value])
            })
        CatalogApi.getCountries().then(resp => setCountryOptions(resp.body))
        CatalogApi.getAdNetworks().then(resp => setAdNetworkOptions(resp.body))
        CatalogApi.getAdUnits().then(resp => setAdUnitsOptions(resp.body))
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage(null);
    };

    const loadTableData = () => {
        const body = {
            mainPeriodStart: toISODate(mainPeriodStart),
            mainPeriodEnd: toISODate(mainPeriodEnd),
            periodToCompareStart: periodToCompareStart ? toISODate(periodToCompareStart) : null,
            periodToCompareEnd: periodToCompareEnd ? toISODate(periodToCompareEnd) : null,
            appIds,
            country,
            adNetwork,
            adUnitName,
            adType,
            trafficByTag,
            trafficWithoutTag,
            grouping,
            compareMetric
        }
        setLoading(true)

        setGridState(undefined)
        post("/api/ad-monetization", body)
            .then(resp => {
                const metricsToShow = compareMetric ? [compareMetric, ...outputMetrics] : outputMetrics;
                const gridState = {
                    colHeaders: ['Grouping', ...metricsToShow.map(m => METRICS[m])],
                    columns: [...metricsToShow],
                    metrics: resp.body
                }
                setGridState(gridState)
            })
            .catch((resp) => setErrorMessage(JSON.stringify(resp.body)))
            .finally(() => setLoading(false))
    }

    const exportData = () => {
        const hot = hotTableComponent.current.hotInstance;
        const exportPlugin = hot.getPlugin('exportFile');

        exportPlugin.downloadFile('csv', {
            bom: false,
            columnDelimiter: ',',
            columnHeaders: true,
            exportHiddenColumns: false,
            exportHiddenRows: false,
            fileExtension: 'csv',
            filename: `${grouping}_export_[YYYY]-[MM]-[DD]`,
            mimeType: 'text/csv',
            rowDelimiter: '\r\n',
            rowHeaders: false
        });
    }

    function toISODate(momentValue) {
        const dt = momentValue.toDate();
        return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000).toISOString()
    }

    const formInvalid = () => {
        return !appIds || appIds.length === 0 || !outputMetrics || outputMetrics.length === 0
    }

    const cellRenderer = (hotInstance, td, rowIndex, column, prop, value, cellProperties) => {
        let formattedValue = roundValue(value);
        let metrics = gridState['metrics'];
        let comparablePeriodMetrics = metrics['comparablePeriodMetrics'];
        let mainPeriodMetrics = metrics['mainPeriodMetrics'];

        if (comparablePeriodMetrics.length > 0) {
            let row = mainPeriodMetrics[rowIndex];
            let grouping = row.grouping;
            const comparableRowIndex = comparablePeriodMetrics.findIndex(r => r.grouping === grouping);

            if (comparableRowIndex >= 0 && value) {

                let positionDiffHtml = ''
                if (compareMetric && prop === compareMetric) {
                    const positionDiff = comparableRowIndex - rowIndex;
                    if (positionDiff !== 0) {
                        positionDiffHtml = positionDiff > 0
                            ? `<span style="background-color:#D3F1D4">&#8593;+${positionDiff}</span>  `
                            : `<span style="background-color:#ff9f90">&#8595;${positionDiff}</span>  `
                    }
                }

                const comparableValue = comparablePeriodMetrics[comparableRowIndex][prop];
                const diff = (value - comparableValue) / value * 100.0;

                let diffFormattedValue = '(' + (diff >= 0 ? '+' : '') + roundValue(diff) + '%' + ')'
                let diffBackgroundColor = diff >= 0 ? '#D3F1D4' : '#ff9f90'
                td.innerHTML = `${formattedValue} ${positionDiffHtml}<span style="background-color: ${diffBackgroundColor}">${diffFormattedValue}</span>`
            } else {
                td.innerHTML = `${formattedValue} <span style="background-color: #FEF9CD">(N/A)</span>`
            }
        } else {
            td.innerText = formattedValue
        }

        return td
    }

    function roundValue(value) {
        return value % 1 !== 0 ? Math.round((value + Number.EPSILON) * 100) / 100 : value;
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Snackbar open={!!errorMessage} autoHideDuration={6_000} onClose={handleCloseSnackBar}>
                <MuiAlert severity="error">{errorMessage}</MuiAlert>
            </Snackbar>
            <Card>
                <CardContent>
                    <h5>Filters</h5>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <DateRangePicker
                                    initialSettings={{
                                        startDate: mainPeriodStart,
                                        endDate: mainPeriodEnd,
                                        alwaysShowCalendars: true
                                    }}
                                    onApply={(event, picker) => {
                                        setMainPeriodStart(picker.startDate)
                                        setMainPeriodEnd(picker.endDate)
                                    }}
                                >
                                    <div className="form-group">
                                        <TextField id="standard-basic" style={{width: "100%"}}
                                                   label="Main period"
                                                   InputProps={{readOnly: true}}
                                                   value={mainPeriodStart.format('MM/DD/YYYY') + ' - ' + mainPeriodEnd.format('MM/DD/YYYY')}/>
                                    </div>
                                </DateRangePicker>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5}>
                            <Autocomplete
                                multiple
                                value={appIds}
                                onChange={(event, newValue) => setAppIds(newValue)}
                                options={appOptions.map(opt => opt.value)}
                                disableClearable
                                getOptionLabel={(option) => appOptions.find(opt => opt.value === option).label || ''}
                                renderTags={(value, _getTagProps) => {
                                    const label = value.map(v => appOptions.find(opt => opt.value === v).label)
                                    return label.join(', ')
                                }}
                                renderInput={(params) => <TextField {...params} label="App" variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <Autocomplete
                                multiple
                                value={country}
                                onChange={(event, newValue) => setCountry(newValue)}
                                options={countryOptions}
                                disableClearable
                                renderTags={(value, _getTagProps) => value.join(', ')}
                                renderInput={(params) => <TextField {...params} label="Countries"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <DateRangePicker
                                    startDate={periodToCompareStart}
                                    endDate={periodToCompareEnd}
                                    initialSettings={{
                                        maxDate: moment().subtract(1, 'days')
                                    }}
                                    onApply={(event, picker) => {
                                        setPeriodToCompareStart(picker.startDate)
                                        setPeriodToCompareEnd(picker.endDate)
                                    }}
                                    locale={{cancelLabel: 'Clear'}}
                                    onCancel={() => {
                                        setPeriodToCompareStart(undefined)
                                        setPeriodToCompareEnd(undefined)
                                        setCompareMetric(undefined)
                                    }}
                                    alwaysShowCalendars={true}>
                                    <div className="form-group">
                                        <TextField id="standard-basic" style={{width: "100%"}}
                                                   InputProps={{readOnly: true}}
                                                   label="Period to compare"
                                                   value={periodToCompareStart && periodToCompareEnd ? (periodToCompareStart.format('MM/DD/YYYY') + ' - ' + periodToCompareEnd.format('MM/DD/YYYY')) : ''}/>
                                    </div>
                                </DateRangePicker>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5}>
                            <Autocomplete
                                multiple
                                value={adNetwork}
                                onChange={(event, newValue) => setAdNetwork(newValue)}
                                options={adNetworkOptions}
                                renderTags={(value, _getTagProps) => value.join(', ')}
                                renderInput={(params) => <TextField {...params} label="Ad Network"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                            <Autocomplete
                                multiple
                                options={AD_TYPE_OPTIONS.map(opt => opt.value)}
                                getOptionLabel={(option) => AD_TYPE_OPTIONS.find(opt => opt.value === option).label || ''}
                                renderTags={(value, _getTagProps) => {
                                    const label = value.map(v => AD_TYPE_OPTIONS.find(opt => opt.value === v).label)
                                    return label.join(', ')
                                }}
                                value={adType}
                                onChange={(event, newValue) => setAdType(newValue)}
                                renderInput={(params) => <TextField {...params} label="Ad Type"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                multiple
                                value={adUnitName}
                                onChange={(event, newValue) => setAdUnitName(newValue)}
                                options={adUnitsOptions}
                                renderTags={(value, _getTagProps) => value.join(', ')}
                                renderInput={(params) => <TextField {...params} label="Ad Unit Name"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <FormControlLabel label="Traffic by TAG" control={
                                <Checkbox checked={trafficByTag}
                                          onChange={(event) => {
                                              setTrafficWithoutTag(false)
                                              setTrafficByTag(event.target.checked)
                                          }}/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <FormControlLabel label="Traffic without TAG" control={
                                <Checkbox checked={trafficWithoutTag}
                                          onChange={(event) => {
                                              setTrafficByTag(false)
                                              setTrafficWithoutTag(event.target.checked)
                                          }}/>}
                            />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent>
                    <h5>Metrics and Grouping</h5>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={12} md={6}>
                            <Autocomplete
                                multiple
                                value={outputMetrics}
                                onChange={(event, newValue) => setOutputMetrics(newValue)}
                                options={outputMetricsOptions}
                                getOptionLabel={(option) => METRICS[option] || ''}
                                disableClearable
                                renderTags={(value, _getTagProps) => value.map(v => METRICS[v]).join(', ')}
                                renderInput={(params) => <TextField {...params} label="Output Metrics"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <Autocomplete
                                value={compareMetric}
                                onChange={(event, newValue) => {
                                    setCompareMetric(newValue)
                                    if (newValue) {
                                        setOutputMetricsOptions(Object.keys(METRICS).filter(m => m !== newValue))
                                        setOutputMetrics(outputMetrics.filter(m => m !== newValue))
                                    } else {
                                        setOutputMetricsOptions(Object.keys(METRICS))
                                    }
                                }}
                                options={Object.keys(METRICS)}
                                disabled={!periodToCompareStart}
                                getOptionLabel={(option) => METRICS[option] || ''}
                                renderInput={(params) => <TextField {...params} label="Metric to compare"
                                                                    variant="standard"/>}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3}>
                            <Autocomplete
                                value={grouping}
                                onChange={(event, newValue) => setGrouping(newValue)}
                                options={Object.keys(GROUPING)}
                                getOptionLabel={(option) => GROUPING[option] || ''}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Grouping"
                                                                    variant="standard"/>}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Button style={{marginTop: '1rem'}} variant="contained" color="primary"
                    disabled={formInvalid() || loading}
                    onClick={() => loadTableData()}>Compare</Button>

            <Button style={{marginTop: '1rem', marginLeft: '1rem'}} variant="contained" color="primary"
                    disabled={!gridState || loading}
                    onClick={() => exportData()}>Export</Button>

            {!!gridState &&
            <Card>
                <CardContent>
                    <HotTable
                        ref={hotTableComponent}
                        data={gridState['metrics']['mainPeriodMetrics']}
                        height="auto"
                        style={{textAlign: 'left'}}
                        stretchH="all"
                        colHeaders={gridState['colHeaders']}
                        columns={[
                            {data: 'grouping', type: 'text', readOnly: true},
                            ...gridState['columns'].map(metric => ({
                                data: metric,
                                readOnly: true,
                                renderer: cellRenderer
                            }))
                        ]}
                        licenseKey="non-commercial-and-evaluation">
                    </HotTable>
                </CardContent>
            </Card>
            }
        </React.Fragment>
    )
}
