import React from "react";
import {Input, Label} from "reactstrap";
import Select from "react-select";

export default function MultiSelectWithExclude(props) {

    const [value, setValue] = React.useState({...props.value});

    return (
        <div>
            {props.label && <Label>{props.label}</Label>}

            <div style={{
                'height': '100%',
                'display': 'flex',
                'flexWrap': 'nowrap',
                'alignItems': 'flex-end'

            }}>
                <div style={{'flexGrow': '1'}}>
                    <Select
                        id={props.id}
                        name={props.id}
                        options={props.options}
                        value={props.value?.selectedOptions}
                        onChange={(selectedOptions, _element) => {
                            let newValue = {...value, selectedOptions};
                            setValue(newValue)
                            props.onChange(newValue)
                        }}
                        isMulti
                    />
                </div>

                <div style={{'width': '60px'}}>
                    <Input checked={props.value?.exclude}
                           onChange={(event) => {
                               let exclude = event.target.checked;
                               let newValue = {...value, exclude};
                               setValue(newValue)
                               props.onChange(newValue)
                           }}
                           type="checkbox" style={{'position': 'relative', 'marginLeft': '0'}}/>
                    <Label style={{'marginLeft': '0.2rem'}} check>Excl.</Label>
                </div>

            </div>
        </div>
    )
}