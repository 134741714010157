import {get} from "../common/ApiUtils";
import * as Formatter from "../common/ValueFormatters";
import * as Getter from "../common/ValueGetters";

export class CatalogApi {

    static getCountries() {
        return get("/api/catalog/countries")
    }

    static getAdNetworks() {
        return get("/api/catalog/ad_network")
    }

    static getAdUnits() {
        return get("/api/catalog/ad_units")
    }

    static getAppWithPlatformOptions() {
        return get("/api/catalog/apps-with-platform").then(this.responseDictToOptions)
    }

    static getMainAppWithPlatformOptions() {
        return get("/api/catalog/apps-with-platform/main").then(this.responseDictToOptions)
    }

    static getCountriesOptions() {
        return get("/api/catalog/countries").then(this.responseListToOptions)
    }

    static getActiveCampaigns() {
        return get("/api/catalog/activeCampaigns").then((resp) => resp.body)
    }

    static getMediaSourceOptions() {
        return get("/api/catalog/mediaSources").then(this.responseDictToOptions)
    }

    static getUamReportCampaigns() {
        return get("/api/catalog/uamReportCampaigns").then(this.responseListToOptions)
    }

    static getPlatformOptions() {
        return get("/api/catalog/platforms").then(this.responseDictToOptions)
    }

    static getAppNameOptions() {
        return get("/api/catalog/app-names").then(this.responseListToOptions)
    }

    static getAsoSourceOptions() {
        return get("/api/catalog/aso-source-options").then(this.responseListToOptions)
    }

    static getFullAppNameOptionsUarm() {
        return get("/api/catalog/full-app-names/uarm").then(this.responseListToOptions)
    }

    static getFullAppNameOptionsCreative() {
        return get("/api/catalog/full-app-names/creative").then(this.responseListToOptions)
    }

    static getFullAppNameOptionsEvent() {
        return get("/api/catalog/full-app-names/event").then(this.responseListToOptions)
    }

    static getFullAppNameOptionsUamSkanOnly() {
        return get("/api/catalog/full-app-names/uam-skan-only").then(this.responseListToOptions)
    }

    static getEvents() {
        return get("/api/catalog/events").then(this.toEventColumns)
    }

    static getUsersForProfileShare() {
        return get('/api/catalog/users-list').then(this.responseDictToOptions)
    }

    static toEventColumns(response) {
        let eventsColumns = [];
        for (let event of response.body) {
            const eventCountCol = {
                colId: event,
                field: `events_count_${event}`,
                filter: "agSetColumnFilter",
                headerName: `Event -> ${event}`,
                aggFunc: 'custom',
                type: "numericColumn",
                valueFormatter: Formatter.numberFormatter(0),
                hide: true
            }
            eventsColumns.push(eventCountCol);

            const eventPercentageCol = {
                colId: event,
                field: `events_count_percentage_${event}`,
                headerName: `Event -> ${event} (%)`,
                valueGetter: Getter.eventsCountPercentGetter,
                suppressFilter: true,
                suppressSorting: true,
                type: "numericColumn",
                valueFormatter: Formatter.percentageFormatter(1),
                hide: true
            }
            eventsColumns.push(eventPercentageCol);
        }
        return eventsColumns
    }

    static getAudienceQueryOptions() {
        return get("/api/catalog/audience-queries").then(resp => {
            const queryOptions = [];
            resp.body.forEach(elem => queryOptions.push({
                value: elem.id,
                label: elem.name,
                filters: JSON.parse(elem.filters),
                id: "query"
            }));
            return queryOptions;
        });
    }

    static getAutoUpdateAudienceQueryOptions() {
        return get("/api/catalog/autoupdate-audience-queries").then(resp => {
            const queryOptions = [];
            resp.body.forEach(elem => queryOptions.push({
                value: elem.id,
                label: elem.name,
                filters: JSON.parse(elem.filters),
                id: "query"
            }));
            return queryOptions;
        });
    }

    static responseListToOptions(resp) {
        return new Promise((resolve, _reject) => resolve(resp.body.map(elem => ({value: elem, label: elem}))));
    }

    static responseDictToOptions(resp) {
        return Object.entries(resp.body).map(([key, value]) => ({value: value, label: key}));
    }
}


