import {FilterType, ReportUtil} from "./ReportUtils";
import {CatalogApi} from "../../api/CatalogApi";

const WEEK_START_OPTIONS = [
    {label: "Monday", value: 0},
    {label: "Tuesday", value: 1},
    {label: "Wednesday", value: 2},
    {label: "Thursday", value: 3},
    {label: "Friday", value: 4},
    {label: "Saturday", value: 5},
    {label: "Sunday", value: 6},
];

const TAG_OPTIONS = [
    {label: 'MEGA', value: 'mega'},
    {label: 'INCENT', value: 'incent'},
    {label: 'ABID', value: 'abid'},
    {label: 'RTRG', value: 'rtrg'},
    {label: 'ROAS', value: 'roas'},
    {label: 'tROAS', value: 'troas'},
    {label: 'CPI', value: 'cpi'},
    {label: 'CPA', value: 'cpa'},
    {label: 'adROAS', value: 'adroas'}
];

const ADDITIONAL_FILTERS_OPTIONS = [
    {label: "Hide paused", value: 'hidePaused'},
    {label: "Hide inactive", value: 'hideInactive'},
    {label: "Hide autobidder", value: 'hideAutobidderCampaigns'},
    {label: "Show SKAN", value: 'showSKAN'},
    {label: "Core Only", value: 'coreOnly'},
    {label: "Test Only", value: 'testOnly'},
];

const TRAFFIC_TYPE_OPTIONS = [
    {label: "Paid", value: "paid"},
    {label: "Organic", value: "organic"},
    {label: "Applovin", value: "applovin"},
    {label: "Tests", value: "tests"},
    {label: "Retargeting", value: "retargeting"},
    {label: "Applovin Retargeting", value: "applovin_retargeting"},
];

const CREATIVE_TYPE_OPTIONS = [
    {value: "video", label: "Video"},
    {value: "playable", label: "Playable"},
];

const CREATIVE_REPORT_COUNTRY_OPTIONS = [
    {value: "AU", label: "AU"},
    {value: "BR", label: "BR"},
    {value: "CA", label: "CA"},
    {value: "CN", label: "CN"},
    {value: "DE", label: "DE"},
    {value: "ES", label: "ES"},
    {value: "FR", label: "FR"},
    {value: "IT", label: "IT"},
    {value: "JP", label: "JP"},
    {value: "KR", label: "KR"},
    {value: "RU", label: "RU"},
    {value: "TW", label: "TW"},
    {value: "UK", label: "UK"},
    {value: "US", label: "US"},
    {value: "REST", label: "REST"},
];

const CREATIVE_REPORT_MEDIA_SOURCES = [
    {value: "adikteev", label: "Adikteev"},
    {value: 'applovin_int', label: "Applovin"},
    {value: "bytedanceglobal_int", label: "Bytedance Global"},
    {value: "chartboosts2s_int", label: "Chartboost"},
    {value: "Facebook Ads", label: "Facebook Ads"},
    {value: "fb_new", label: "FB new"},
    {value: "googleadwords_int", label: "Google Ads"},
    {value: "liftoff_int", label: "Liftoff"},
    {value: "mintegral_int", label: "Mintegral"},
    {value: "Moloco Ads", label: "Moloco Ads"},
    {value: "remerge_int", label: "Remerge"},
    {value: "unityads_int", label: "Unity Ads"},
    {value: "vungle_int", label: "Vungle"},
]

export class FilterDef {

    static NEW_LINE = {
        type: FilterType.NEW_LINE,
    }

    static TITLE = {
        key: 'app_name',
        title: 'Title',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getAppNameOptions(),
    }

    static APP = {
        key: 'full_app_name',
        title: 'App',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getFullAppNameOptionsUarm(),
    }

    static PLATFORM = {
        key: 'platform',
        title: 'Platform',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getPlatformOptions(),
    }

    static MEDIA_SOURCE = {
        key: 'media_source',
        title: 'Media Source',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getMediaSourceOptions(),
    }

    static CAMPAIGN = {
        key: 'campaign',
        title: 'Campaign',
        type: FilterType.INPUT,
        defaultValue: '',
    }

    static UAM_REPORT_CAMPAIGN = {
        key: 'campaign',
        title: 'Campaign',
        type: FilterType.MULTISELECT,
        isCreatable: true,
        useLikeOperator: true,
        optionsFunc: () => CatalogApi.getUamReportCampaigns(),
    }

    static COUNTRY = {
        key: 'country_code',
        title: 'Country',
        type: FilterType.MULTISELECT_WITH_EXCLUDE,
        showExclude: true,
        optionsFunc: () => CatalogApi.getCountriesOptions(),
    }

    static INSTALL_DATE = {
        key: 'install_date',
        title: 'Date Range',
        type: FilterType.DATE_RANGE,
        defaultValue: {
            startDate: ReportUtil.getDefaultStartDate(),
            endDate: ReportUtil.getDefaultEndDate()
        }
    }

    static TRAFFIC_TYPE = {
        key: 'traffic_type',
        title: 'Traffic Type',
        type: FilterType.MULTISELECT,
        options: TRAFFIC_TYPE_OPTIONS,
    }

    static CREATIVE_TYPE = {
        key: 'creative_type',
        title: 'Creative Type',
        type: FilterType.SELECT,
        isClearable: true,
        options: CREATIVE_TYPE_OPTIONS,
    }

    static CREATIVE_REPORT_COUNTRY = {
        key: 'country_code',
        title: 'Country',
        type: FilterType.MULTISELECT_WITH_EXCLUDE,
        options: CREATIVE_REPORT_COUNTRY_OPTIONS,
    }

    static CREATIVE_REPORT_MEDIA_SOURCE = {
        key: 'media_source',
        title: 'Media Source',
        type: FilterType.MULTISELECT,
        options: CREATIVE_REPORT_MEDIA_SOURCES,
    }

    static ASO_SOURCE = {
        key: 'source',
        title: 'Source',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getAsoSourceOptions(),
    };

    static WEEK_START = {
        key: 'week_start',
        title: 'Week start',
        type: FilterType.SELECT,
        defaultValue: WEEK_START_OPTIONS[0],
        options: WEEK_START_OPTIONS,
        filterModelFunc: (_value) => ([])
    }

    static CAMPAIGN_TAG = {
        key: 'campaign_tag',
        title: 'TAG',
        type: FilterType.MULTISELECT,
        defaultValue: [],
        options: TAG_OPTIONS,
        filterModelFunc: (selectedOptions) => {
            if (selectedOptions.length > 0) {
                return [{
                    key: 'campaign',
                    filterType: 'string',
                    filter: selectedOptions.map(option => option.value).join(","),
                    type: 'containsMultipleValues'
                }];
            }
            return []
        }
    };

    static ADDITIONAL_FILTERS = {
        key: 'additional_filters',
        title: 'Additional Filters',
        type: FilterType.MULTISELECT,
        defaultValue: [],
        options: ADDITIONAL_FILTERS_OPTIONS,
        filterModelFunc: (value) => {
            let filters = []
            if (value.find((option) => option.value === 'hideAutobidderCampaigns')) {
                filters.push({key: 'campaign', filterType: 'string', filter: '%_abid_%', type: 'not like'});
            }

            if (value.find((option) => option.value === 'hidePaused')) {
                filters.push({key: 'campaign_active', 'filterType': 'boolean', filter: true, type: 'equal'});
            }

            if (value.find((option) => option.value === 'testOnly')) {
                filters.push({key: 'is_test_campaign', 'filterType': 'boolean', filter: true, type: 'equal'});
            }

            if (value.find((option) => option.value === 'coreOnly')) {
                filters.push({key: 'is_test_campaign', filterType: 'boolean', filter: false, type: 'equal'});
            }

            if (!value.find((option) => option.value === 'showSKAN')) {
                filters.push ({key: 'is_skad', filterType: 'boolean', filter: false, 'type': 'equals'});
            }

            return filters
        },
        aggregateFilterModelFunc: (value) => {
            if (value.find((option) => option.value === 'hideInactive')) {
                let filters = [];
                filters.push({key: 'installs', filterType: 'number', filter: 0, type: 'notEqual'});
                filters.push({key: 'spend', filterType: 'number', filter: 0, type: 'notEqual'});
                return filters
            } else {
                return []
            }
        }
    }

    static SITE_ID = {
        key: 'siteid',
        title: 'Site ID',
        type: FilterType.INPUT,
        defaultValue: '',
        filterModelFunc: (value) => ([{key: 'siteid', filterType: 'string', filter: '%' + value + '%', type: 'like'}])
    }

}