import {Label} from "reactstrap";
import CreatableSelect from 'react-select/creatable';
import React from "react";
import Select from "react-select";

export default function MultiSelect(props) {

    return (
        <div>
            {props.label && <Label>{props.label}</Label>}
            {props.isCreatable ?
                <CreatableSelect
                    id={props.id}
                    name={props.id}
                    options={props.options}
                    placeholder={'All'}
                    value={props.value}
                    onChange={(selectedOptions) => props.onChange(selectedOptions)}
                    isMulti
                /> :
                <Select
                    id={props.id}
                    name={props.id}
                    options={props.options}
                    placeholder={'All'}
                    value={props.value}
                    onChange={(selectedOptions) => props.onChange(selectedOptions)}
                    isMulti
                />
            }
        </div>
    )
}
